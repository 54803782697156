import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebase_app } from "./config";

const auth = getAuth(firebase_app);

export const signup = async ({
  first_name,
  last_name,
  email,
  password,
  role,
}) =>
  await createUserWithEmailAndPassword(auth, email, password).then(
    async (authUser) => {
      // NOTE: this will not run because of onAuthStateChanged in _app.js if used through frontend
      // create user in db
      const user = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_URL}/api/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: authUser.user.uid,
            email,
            first_name,
            last_name,
            role,
          }),
        }
      ).then((res) => res.json());

      return {
        ...authUser,
        user: {
          ...authUser.user,
          ...user,
        },
      };
    }
  );

export const login = async ({ email, password }) =>
  await signInWithEmailAndPassword(auth, email, password);

export const logout = async () => await signOut(auth);
