import { useRouter } from "next/router";
import { createContext, useState } from "react";

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const router = useRouter();

  const [hide, setHide] = useState(router?.asPath !== "/");

  return (
    <NavContext.Provider value={{ hide, setHide }}>
      {children}
    </NavContext.Provider>
  );
};
