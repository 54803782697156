import { useRouter } from "next/router";
import { NavProvider } from "@context/NavContext";
import NavBar from "./NavBar";

const Layout = ({ children }) => {
  const router = useRouter();

  return (
    <NavProvider>
      <div className="w-dvw h-dvh flex gap-2 bg-grey-background">
        <NavBar />
        <div className="grow h-full overflow-y-auto p-8">{children}</div>
      </div>
    </NavProvider>
  );
};

export default Layout;
